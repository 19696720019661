import React from "react";

import Swal from "sweetalert2";

import { User } from "../../../Interfaces/User";

import DefaultIcon from "../../../images/profile-pic-icon.png";
import DeleteIcon from "../../../images/delete_dark_icon@2x.png";

import * as ResidenceCardStyle from "./ResidenceCard.module.scss";

interface Props {
  isOwner?: boolean;
  member: User;
  onRemove?: () => void;
}

const Index = ({ member, isOwner, onRemove }: Props) => {
  return (
    <div className={ResidenceCardStyle.card}>
      <div className={ResidenceCardStyle.card__content}>
        {onRemove && (
          <button
            onClick={() => {
              Swal.fire({
                type: "info",
                title: "Warning",
                text: "You Want To Remove This User?",
                showCancelButton: true,
              })
                .then(({ value }) => {
                  if (value) onRemove();
                })
                .catch(() => {});
            }}
            className={ResidenceCardStyle.removebtn}
          >
            <img src={DeleteIcon} alt="delete" width={28} height={28} />
          </button>
        )}

        <img
          src={member.userMedias?.collection[0]?.mediaUrl?.url || DefaultIcon}
          alt="user"
          width={90}
          height={90}
          {...{ style: { borderRadius: "100px", objectFit: "cover" } }}
        />
        <div className={ResidenceCardStyle.card__content__details}>
          {isOwner && (
            <span className={ResidenceCardStyle.tag__owner}>Owner</span>
          )}
          <span>{member.name}</span>
          <span>{member.contactNumber}</span>
          <span>{member.email}</span>
        </div>
      </div>
    </div>
  );
};

export default Index;
