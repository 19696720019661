import React from "react";

import ClipLoader from "react-spinners/ClockLoader";

interface Props {
  isLoading: boolean;
}

const Index = ({ isLoading }: Props) => {
  return (
    <div
      {...{
        style: {
          display: isLoading ? "flex" : "none",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          backgroundColor: "#e9e9e9",
          opacity: "0.2",
          top: "0",
          left: "0",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <ClipLoader color="#051d46" loading={isLoading} size={100} />
    </div>
  );
};

export default Index;
