import React, { useEffect, useState } from "react";

import { navigate } from "gatsby";
import { useQuery } from "@apollo/client";

import { GetResidenceQuery } from "../../Query/ParkingArea";

import Loader from "../Loader";
import ResidenceCard from "./ResidenceCard";
import Modal from "../Modal";
import AddMemberModal from "../Modals/AddMember";

import BackIcon from "../../images/back_arrow_icon@2x.png";
import AddIcon from "../../images/add_new_plus_icon@2x.png";

import Logger from "../../utils/Logger";
import Server from "../../utils/Server";

import { Tower } from "../../Interfaces/ParkingArea";

import * as ResidenceDetailStyle from "./ResidenceDetail.module.scss";

interface Props {
  residenceId: string;
}

interface IAddMember {
  name: string;
  contactNumber: string;
  email?: string;
}

const Index = ({ residenceId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddMember, setIsAddMember] =
    useState<{ isOpen: boolean; type: "RO" | "RT" }>();
  const { data, loading, error, refetch } = useQuery<{ residence: Tower }>(
    GetResidenceQuery,
    {
      variables: {
        id: `/residences/${residenceId}`,
      },
    }
  );

  useEffect(() => {
    if (error) Logger.showError(error);
  }, [error]);

  const isMember = (type: "RO" | "RH" | "RT") => {
    return data?.residence.userResidences.collection?.filter(
      ({ type: { code } }) => code === type
    ).length
      ? true
      : false;
  };

  const removeResidanceMember = (userResidanceId: string) => {
    const showtUserResidanceId = userResidanceId.split("/")[2];
    setIsLoading(true);
    Server.del(`/user/residence/${showtUserResidanceId}/remove`)
      .then((res) => {
        refetch();
      })
      .catch((error) => {
        Logger.showError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const addResidanceMember = (data: IAddMember, type: "RO" | "RT") => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      Server.post(`/residence/${residenceId}`, {
        name: data.name,
        contactNumber: data.contactNumber,
        type: type,
        email: data.email,
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => setIsLoading(false));
    });
  };

  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      <div className={ResidenceDetailStyle.container}>
        <div className={ResidenceDetailStyle.header}>
          <button onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back" width={32} height={32} />
          </button>
        </div>
        <div className={ResidenceDetailStyle.content}>
          <div className={ResidenceDetailStyle.content__section}>
            <div className={ResidenceDetailStyle.content__section__header}>
              <h6>Residence Members</h6>
              <button
                onClick={() => setIsAddMember({ isOpen: true, type: "RO" })}
              >
                <img src={AddIcon} alt="add" width={28} height={28} />
              </button>
            </div>
            <div className={ResidenceDetailStyle.content__section__items}>
              {data?.residence.userResidences.collection
                ?.filter(({ type }) => type.code === "RO")
                .map(({ id, user }, i) => (
                  <ResidenceCard
                    key={id}
                    member={user}
                    isOwner={i === 0}
                    onRemove={() => {
                      removeResidanceMember(id);
                    }}
                  />
                ))}
              {!isMember("RO") && (
                <span className={ResidenceDetailStyle.nodata}>
                  No Residence Member
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={ResidenceDetailStyle.content}>
          <div className={ResidenceDetailStyle.content__section}>
            <div className={ResidenceDetailStyle.content__section__header}>
              <h6>Residence Tenent</h6>
              <button
                onClick={() => setIsAddMember({ isOpen: true, type: "RT" })}
              >
                {/* <span>Add</span> */}
                <img src={AddIcon} alt="add" width={28} height={28} />
              </button>
            </div>

            <div className={ResidenceDetailStyle.content__section__items}>
              {data?.residence.userResidences.collection
                ?.filter(({ type }) => type.code === "RT")
                .map(({ id, user }, i) => (
                  <ResidenceCard
                    key={id}
                    member={user}
                    onRemove={() => {
                      removeResidanceMember(id);
                    }}
                  />
                ))}
              {!isMember("RT") && (
                <span className={ResidenceDetailStyle.nodata}>
                  No Residence Tenent
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={ResidenceDetailStyle.content}>
          <div className={ResidenceDetailStyle.content__section}>
            <h6>Domestci Help</h6>

            <div className={ResidenceDetailStyle.content__section__items}>
              {data?.residence.userResidences.collection
                ?.filter(({ type }) => type.code === "RH")
                .map(({ id, user }, i) => (
                  <ResidenceCard key={id} member={user} />
                ))}
              {!isMember("RH") && (
                <span className={ResidenceDetailStyle.nodata}>
                  No Domestic Help
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isAddMember?.isOpen || false}
        title="Add Residence Member"
        onClose={() => setIsAddMember(undefined)}
        showHeader
      >
        <AddMemberModal
          onFormSubmit={(data) => {
            setIsAddMember(undefined);
            if (isAddMember?.type) {
              setIsLoading(true);
              addResidanceMember(data, isAddMember?.type)
                .then(() => {
                  refetch();
                })
                .catch((err) => {
                  Logger.showError(err);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          }}
        />
      </Modal>
      <Loader isLoading={loading || isLoading} />
    </>
  );
};

export default Index;
