import React from "react";

import Layout from "./../../Components/Layout";
import ReisdenceDetail from "../../Components/ResidenceDetail";

const ResidenceDetailPage = ({ residenceid }) => {
  return (
    <Layout showHeader isPrivate>
      <ReisdenceDetail residenceId={residenceid} />
    </Layout>
  );
};

export default ResidenceDetailPage;
