import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import * as AddMemberStyle from "./AddMember.module.scss";

interface Props {
  onFormSubmit: (data: Inputs) => void;
}

interface Inputs {
  name: string;
  contactNumber: string;
  email?: string;
}

const Index = ({ onFormSubmit }: Props) => {
  const { register, handleSubmit, reset } = useForm();

  const onHandleSubmit: SubmitHandler<Inputs> = (data) => {
    onFormSubmit(data);
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      className={AddMemberStyle.form}
    >
      <div className={AddMemberStyle.form__group}>
        <label htmlFor="name" className={AddMemberStyle.form__group__label}>
          Name:
        </label>
        <input id="name" type="text" {...register("name")} required />
      </div>
      <div className={AddMemberStyle.form__group}>
        <label
          htmlFor="contactnumber"
          className={AddMemberStyle.form__group__label}
        >
          Contact Number:
        </label>
        <input
          id="contactnumber"
          type="number"
          required
          {...register("contactNumber")}
        />
      </div>
      <div className={AddMemberStyle.form__group}>
        <label htmlFor="email" className={AddMemberStyle.form__group__label}>
          Email:
        </label>
        <input id="email" type="email" {...register("email")} />
      </div>

      <div className="loadmore">
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default Index;
