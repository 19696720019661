import Swal from "sweetalert2";

const log = (e: unknown): void => {
  let title = "Oops Something Went Wrong!";
  if (e instanceof Response) {
    const r: Response = e;
    r.text()
      .then((rt) => {
        const errObj = JSON.parse(rt);
        if (errObj.title) {
          title = errObj.title || errObj.type;
        } else {
          console.log(rt, r);
        }
        if (errObj.errors) {
          if (Array.isArray(errObj.errors)) {
            try {
              title += errObj.errors.reduce((pe, ce) => {
                pe += Object.keys(ce).reduce((p, c) => {
                  return `${p}<b>${c}: </b> ${ce[c]} <br />`;
                }, "<br />");
              }, "<br />");
            } catch (error) {
              console.log(error);
            }
          } else {
            title += Object.keys(errObj.errors).reduce((p, c) => {
              return `${p}<b>${c}: </b> ${errObj.errors[c]} <br />`;
            }, "<br />");
          }
        } else {
          console.log(rt, r);
        }

        if (errObj.error) {
          console.log(errObj.error.title);

          title = errObj.error.title || errObj.error.type;
        } else {
          console.log(rt, r);
        }
        Swal.fire("😥", title, "error");
      })
      .catch((error) => {
        Swal.fire("😥", title, "error");
        console.log(error, r);
      });
  } else if (e instanceof Error) {
    Swal.fire("😥", `<b>${e.name}</b><br />${e.message}`, "error");
    console.log(e);
  } else {
    Swal.fire("😥", title, "error");
    console.log(e);
  }
  throw e;
};

const showError = (e: any): void => {
  let tempErr = "";
  try {
    const obj = JSON.parse(e?.response?.data?.detail);
    tempErr = Object.keys(obj).reduce(
      (a, key) => `${a}<br>${key.toUpperCase()} : ${obj[key]}`,
      ""
    );
  } catch {
    // console.log(error)
  }

  Swal.fire({
    type: "error",
    title: "Error",
    html:
      tempErr ||
      e?.response?.data?.detail ||
      e?.response?.data?.message ||
      e?.response?.data?.error?.title ||
      e?.response?.data?.error?.message ||
      e?.message ||
      e?.title ||
      "Oops something went wrong",
  });
};
const Logger = { log, showError };
export default Logger;
